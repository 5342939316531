<template>
  <!-- 企业合同页面 -->
  <div>
    <!-- 头部开始 -->
    <el-row class="pur-top">
      <el-col :span="3"
        ><span class="pur-size">{{ purTitle }}</span></el-col
      >
      <el-col :span="21" class="pur-right">
        <!-- <el-button size="small" @click="addPurs">新建</el-button> -->
        <el-input
          @input="search"
          size="small"
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="purSearch"
        >
        </el-input>
        <el-date-picker
          size="small"
          @change="Times"
          v-model="TimeArrays"
          type="daterange"
          unlink-panels
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          style="width: 20%; float: right; margin-right: 16px"
        >
        </el-date-picker>

        <el-select
          ref="comref"
          v-model="companyId"
          clearable
          filterable
          size="small"
          placeholder="请选择付款企业"
          @change="compaypro"
        >
          <el-option
            v-for="item in companydata"
            :key="item.buyerUnitId"
            :label="item.buyerUnit"
            :value="item.buyerUnitId"
          >
          </el-option>
        </el-select>
        <div
          style="
            float: right;
            height: 32px;
            line-height: 32px;
            width: auto;
            margin-right: 8px;
          "
        >
          付款企业:
        </div>
        <el-select
          ref="comref"
          v-model="skcompanyId"
          clearable
          filterable
          size="small"
          placeholder="请选择收款企业"
          @change="skcompaypro"
        >
          <el-option
            v-for="item in skcompanydata"
            :key="item.salesUnitId"
            :label="item.salesUnit"
            :value="item.salesUnitId"
          >
          </el-option>
        </el-select>
        <div
          style="
            float: right;
            height: 32px;
            line-height: 32px;
            width: auto;
            margin-right: 8px;
          "
        >
          收款企业:
        </div>
        <el-select v-model="carriageName" size="small" @change="prologistics">
          <el-option
            v-for="item in Extended"
            :key="item.value"
            :label="item.dkey"
            :value="item.dvalue"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav" v-loading="loading" element-loading-text="加载中...">
      <!-- 表格 -->
      <div class="pur-table">
        <el-table
          height="100%"
          border
          :data="tableData"
          :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
          style="width: 100%"
        >
          <el-table-column prop="id" v-if="show"> </el-table-column>
          <el-table-column
            label="操作"
            :show-overflow-tooltip="true"
            width="150"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.contractStatus == 2 &&
                  scope.row.residueFreezeAmt != 0">
                <el-button size="mini" @click="gopayment(scope.row, 1)"
                  >解付</el-button
                >
                <el-button size="mini" @click="gopayment(scope.row, 2)"
                  >退款</el-button
                >
              </template>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column
            label="合同编号"
            sortable
            :show-overflow-tooltip="true"
            width="240"
          >
            <template slot-scope="scope">
              <div>
                <span
                  style="color: #cd1724; cursor: pointer"
                  @click="purSee(scope.row)"
                  >{{ scope.row.contractCode }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="订单状态"
            :show-overflow-tooltip="true"
            width="160"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.contractStatus == 1">
                <div
                  class="statsBox"
                  style="background: #cf1724;"
                ></div>
                <span> 未付款</span>
              </div>
              <div v-if="scope.row.contractStatus == 2">
                <div
                  class="statsBox"
                  style="background: #389DFB;"
                ></div>
                <span> 执行中</span>
              </div>
              <div v-if="scope.row.contractStatus == 3">
                <div
                  class="statsBox"
                  style="background: #999;"
                ></div>
                <span> 交易关闭</span>
              </div>
              <div v-if="scope.row.contractStatus == 4">
                <div
                  class="statsBox"
                  style="background: #3fbf9c; box-shadow: 0px 4px 4px #ffe2d0"
                ></div>
                <span> 订单完成</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="合同总价金额（元）"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.sumPrice">{{
                scope.row.sumPrice | currency
              }}</span>
              <span v-else>0.00</span>
            </template>
          </el-table-column>
          <el-table-column
            label="已付冻结总额（元）"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.sumFreezeAmt">{{
                scope.row.sumFreezeAmt | currency
              }}</span>
              <span v-else>0.00</span>
            </template>
          </el-table-column>
          <el-table-column
            label="已解付金额（元）"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.sumThawAmt">{{
                scope.row.sumThawAmt | currency
              }}</span>
              <span v-else>0.00</span>
            </template>
          </el-table-column>
          <el-table-column
            label="已退款总额（元）"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.sumRefundAmt">{{
                scope.row.sumRefundAmt | currency
              }}</span>
              <span v-else>0.00</span>
            </template>
          </el-table-column>
          <el-table-column
            label="剩余待解付金额（元）"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.residueFreezeAmt">{{
                scope.row.residueFreezeAmt | currency
              }}</span>
              <span v-else>0.00</span>
            </template>
          </el-table-column>
          <el-table-column
            label="已分润总额(元)"
            width="150"
            :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.sumTraceFees"
                >{{ scope.row.sumTraceFees | currency }}
              </span>
              <span v-else>0.00</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="salesUnit"
            label="销售单位"
            sortable
            width="240"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="buyerUnit"
            label="购货单位"
            :show-overflow-tooltip="true"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="payRate"
            label="首次付款比例"
            sortable
            :show-overflow-tooltip="true"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="deliveryPlace"
            label="发货地"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="receiptPlace"
            label="收货地"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- <el-table-column label="产品大类" :show-overflow-tooltip="true">
            <template slot-scope="scope" v-if="scope.row.proBigType">
              {{ scope.row.proBigType | protype }}
            </template>
          </el-table-column>
          <el-table-column label="产品小类" :show-overflow-tooltip="true">
            <template slot-scope="scope" v-if="scope.row.proSmaType">
              {{ scope.row.proSmaType | promiddletype }}
            </template>
          </el-table-column> -->
          <el-table-column label="煤炭选择" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.fstName }}
            </template>
          </el-table-column>
          <el-table-column label="煤炭分类" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.secName }}
            </template>
          </el-table-column>
          <el-table-column label="煤炭品种" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{ scope.row.thName }}
            </template>
          </el-table-column>
          <el-table-column
            prop="contractNum"
            label="合同数量"
            width="120"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <!-- 暂无数据 -->
          <div slot="empty" class="dataAvailable" v-if="dataAva">
            <div class="dataAva">
              <img src="../../assets/zanwushuju.png" alt="" />
              <p>暂无数据，您可以新建试试</p>
            </div>
          </div>
        </el-table>
      </div>
      <!-- 抽屉 查看合同功能 -->
      <el-drawer title="合同信息" :visible.sync="drawer" size="700px">
        <div class="pur-tiao"></div>
        <div class="pur-drawerNav">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="first">
              <el-row>
                <el-col :span="24">
                  <div class="see-top">基础信息</div>
                  <ul class="see-lable">
                    <li>
                      <label>系统编码：</label>
                      <span>{{ seeData.systemCode }}</span>
                    </li>
                    <li>
                      <label>项目类型：</label>
                      <span v-if="seeData.contractResource === 1"
                        >供应链业务</span
                      >
                      <span v-if="seeData.contractResource === 2"
                        >供应链金融</span
                      >
                    </li>
                    <li>
                      <label>合同编号：</label>
                      <span v-if="seeData.contractCode">{{
                        seeData.contractCode
                      }}</span>
                      <span v-else>---</span>
                    </li>
                    <li>
                      <label>合同名称：</label>
                      <span>{{ seeData.contractName }}</span>
                    </li>
                    <li>
                      <label>签订时间：</label>
                      <span>{{ seeData.signingTime }}</span>
                    </li>
                    <li>
                      <label>所属月份：</label>
                      <span v-if="seeData.month">{{
                        seeData.month | timeDate
                      }}</span>
                    </li>
                    <li>
                      <label>销售单位：</label>
                      <span>{{ seeData.salesUnit }}</span>
                    </li>
                    <li>
                      <label>购货单位：</label>
                      <span>{{ seeData.buyerUnit }}</span>
                    </li>
                    <li>
                      <label>发货地：</label>
                      <span>{{ seeData.deliveryPlace }}</span>
                    </li>
                    <li>
                      <label>收货地：</label>
                      <span>{{ seeData.receiptPlace }}</span>
                    </li>
                  </ul>
                </el-col>
                <el-col :span="24">
                  <div class="see-top">产品信息</div>
                  <ul class="see-lable">
                    <li>
                      <label>煤炭选择：</label>
                      <span>{{ seeData.fstName }} </span>
                    </li>
                    <li>
                      <label>煤炭分类</label>
                      <span>{{ seeData.secName }}</span>
                    </li>
                    <li>
                      <label>煤炭品种</label>
                      <span>{{ seeData.thName }}</span>
                    </li>
                    <!-- <li>
                        <label>产品名称：</label>
                        <span v-if="seeData.proSmaType == 20">{{seeData.proRemark}}</span>
                        <span v-else>---</span>
                      </li> -->
                    <li>
                      <label>合同数量：</label>
                      <span>{{ seeData.contractNum }} 吨</span>
                    </li>
                    <li>
                      <label>货品单价：</label>
                      <span>{{ seeData.unitPrice }} 元 / 吨</span>
                    </li>
                    <li>
                      <label>总价金额：</label>
                      <span v-if="seeData.sumPrice"
                        >{{ seeData.sumPrice | currency }} 元</span
                      >
                    </li>
                    <li>
                      <label>标的指标：</label>
                      <div class="see-libox">
                        <div v-if="seeData.sulfur">
                          <span>硫分St.d%:</span>
                          <span>{{ seeData.sulfur }},</span>
                        </div>
                        <div v-if="seeData.minAshContent">
                          <span> 灰分Ad%≤:</span>
                          <span v-if="seeData.minAshContent"
                            >{{ seeData.minAshContent }},</span
                          >
                        </div>
                        <div v-if="seeData.volatilization">
                          <span> 挥发分Vdaf%:</span>
                          <span>{{ seeData.volatilization }},</span>
                        </div>
                        <div v-if="seeData.water">
                          <span> 水分Mt%:</span>
                          <span>{{ seeData.water }},</span>
                        </div>
                        <div v-if="seeData.minBondIndex">
                          <span> 粘结指数G%≥:</span>
                          <span>{{ seeData.minBondIndex }},</span>
                        </div>
                        <div v-if="seeData.minY">
                          <span> 胶质层Ymm≥:</span>
                          <span>{{ seeData.minY }},</span>
                        </div>
                        <div v-if="seeData.minCsr">
                          <span> 热态CSR≥:</span>
                          <span>{{ seeData.minCsr }},</span>
                        </div>
                        <div v-if="seeData.lithofacies">
                          <span> 岩相标准差S:</span>
                          <span>{{ seeData.lithofacies }},</span>
                        </div>
                        <div v-if="seeData.cleanCoalRecoveryRate">
                          <span> 精煤回收率%≥:</span>
                          <span>{{ seeData.cleanCoalRecoveryRate }},</span>
                        </div>
                        <div v-if="seeData.minHgi">
                          <span> 哈氏可磨指数(HGI)≥:</span>
                          <span>{{ seeData.minHgi }},</span>
                        </div>
                        <div v-if="seeData.calorificValue">
                          <span> 热值:</span>
                          <span>{{ seeData.calorificValue }},</span>
                        </div>
                        <div v-if="seeData.gangue">
                          <span> 矸石:</span>
                          <span>{{ seeData.gangue }},</span>
                        </div>
                        <div v-if="seeData.slurry">
                          <span> 煤泥:</span>
                          <span>{{ seeData.slurry }},</span>
                        </div>
                        <div v-if="seeData.middlingCoal">
                          <span> 中煤(%):</span>
                          <span>{{ seeData.middlingCoal }} </span>
                        </div>
                      </div>
                    </li>
                    <li style="clear: both">
                      <label>备注：</label>
                      <span v-if="seeData.remark">{{ seeData.remark }}</span>
                      <span v-else>---</span>
                    </li>
                  </ul>
                </el-col>
                <el-col :span="24">
                  <div class="see-top">扩展信息</div>
                  <ul class="see-lable">
                    <li>
                      <label>价格类型：</label>
                      <span v-if="seeData.priceType">{{
                        seeData.priceType | pricetype
                      }}</span>
                    </li>
                    <li>
                      <label>付款方式：</label>
                      <span v-if="seeData.payMethod">{{
                        seeData.payMethod | paymentmethod
                      }}</span>
                    </li>
                    <li>
                      <label>支付方式：</label>
                      <span v-if="seeData.payType">{{
                        seeData.payType | paymenttype
                      }}</span>
                    </li>
                    <li>
                      <label>运输模式：</label>
                      <span v-if="seeData.transportMode">{{
                        seeData.transportMode | transportmode
                      }}</span>
                    </li>
                    <li>
                      <label>承运工具：</label>
                      <span v-if="seeData.carrier">{{
                        seeData.carrier | carrier
                      }}</span>
                    </li>
                  </ul>
                </el-col>
                <el-col :span="24">
                  <div class="see-top">扩展信息</div>
                  <dl class="see-file">
                    <dd>
                      <el-col :span="5">
                        <span>上传合同附件：</span>
                      </el-col>
                      <el-col :span="19">
                        <div
                          v-for="(v, k) in filelist"
                          :key="k"
                          class="Addbuttonbox"
                          @click="scopImg(k)"
                        >
                          {{ v.name }}
                        </div>
                      </el-col>
                    </dd>
                    <dd>
                      <el-col :span="5">
                        <span>上传补充协议：</span>
                      </el-col>
                      <el-col :span="19" style="padding-bottom: 48px">
                        <a
                          v-for="(v, k) in filelist"
                          :key="k"
                          :href="
                            http +
                            '/saas-common/upload/zjjgDownById?Signature=328b14094b22d98589a0b135efe853eae3cde83b53e8f7920265df1c88c6389f&AuthorizationId=wxda0b1c468b83bc99&fileName=' +
                            v.fileName
                          "
                          @mouseenter="changeActive($event)"
                          @mouseleave="removeActive($event)"
                          ><p v-if="v.fileType == 2">{{ v.name }}</p></a
                        >
                      </el-col>
                    </dd>
                  </dl>
                </el-col>
              </el-row>
            </el-tab-pane>
            <!-- <el-tab-pane label="展示图" name="chart">
              <el-row>
                <el-col :span="24">
                  <div class="chart-title" style="margin: 24px 0">
                    单笔业务数据统计
                  </div>
                  <ul class="chart-data">
                    <li>
                      <div>
                        <i
                          class="el-icon-alihetong1"
                          style="color: #2272ea"
                        ></i>
                        <span>合同</span>
                      </div>
                      <div>
                        <span v-if="searsData.contractMoney">
                          {{ searsData.contractMoney | currency }} 元
                        </span>
                        <span v-else> 0.00 元</span>
                      </div>
                      <div>
                        <span>
                          {{ searsData.contractCount | ToFiexd }} 吨
                        </span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="el-icon-aliwuliu2" style="color: #3fbf9c"></i>
                        <span>运单</span>
                      </div>
                      <div>
                        <span> {{ searsData.loMoney }} </span>
                      </div>
                      <div>
                        <span> {{ searsData.loCount | ToFiexd }} 吨 </span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <i class="el-icon-alizijin" style="color: #f54d36"></i>
                        <span>结算</span>
                      </div>
                      <div>
                        <span v-if="searsData.settMoney">
                          {{ searsData.settMoney | currency }} 元
                        </span>
                        <span v-else> 0.00 元</span>
                      </div>
                      <div>
                        <span> {{ searsData.settCount | ToFiexd }} 吨 </span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <i
                          class="el-icon-aliticket-fill"
                          style="color: #8092c0"
                        ></i>
                        <span>票据</span>
                      </div>
                      <div>
                        <span v-if="searsData.billMoney">
                          {{ searsData.billMoney | currency }} 元
                        </span>
                        <span v-else> 0.00 元</span>
                      </div>
                      <div>
                        <span> {{ searsData.billCount | ToFiexd }} 吨 </span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <i
                          class="el-icon-aliqianbao11"
                          style="color: #f59d36"
                        ></i>
                        <span>流水</span>
                      </div>
                      <div>
                        <span v-if="selcAmts">
                          {{ selcAmts | currency }} 元</span
                        >
                        <span v-else> 0.00 元</span>
                      </div>
                      <div>
                        <span> {{ searsData.detCount }} </span>
                      </div>
                    </li>
                  </ul>
                  <div class="chart-title">单笔业务流程图</div>
                  <div class="chart-leftImg" @click="zstJump">
                    <img :src="zstimg" alt="" />
                    <div class="htl">合同 ( {{ searsData.contractNum }} )</div>
                    <div class="js">结算 ( {{ searsData.settNum }} )</div>
                    <div class="wl">订单 ( {{ searsData.orderNum }} )</div>
                    <div class="zj">资金 ( {{ searsData.detNum }} )</div>
                    <div class="pjl">票据流 ( {{ searsData.billNum }} )</div>
                  </div>
                  <div class="chart-rightImg">
                    <img :src="slhyimg" alt="">
                  </div> 
                </el-col>
              </el-row>
            </el-tab-pane> -->
            <!-- <el-tab-pane label="动态" name="second">
                  <el-row>
                      <el-col :span="24">
                          <div class="see-top" style="margin-bottom:24px;">动态信息</div>
                          <el-timeline>
                              <el-timeline-item v-for="v,i in timeData" :key="i" :timestamp="v.createtime" placement="top">
                                  <el-card>
                                      <h4>{{v.operation}}</h4>
                                  </el-card>
                              </el-timeline-item>
                          </el-timeline>
                      </el-col>
                </el-row>
              </el-tab-pane> -->
          </el-tabs>
          <div class="xia">《</div>
        </div>
      </el-drawer>
      <!-- 查看交易记录功能 -->
      <el-drawer title="交易信息" :visible.sync="recorddrawer" size="700px">
        <div class="pur-tiao"></div>
        <div class="pur-drawerNav">
          <el-row>
            <el-col :span="24">
              <div class="see-top">交易信息</div>
              <ul class="see-lable">
                <li>
                  <label>系统订单：</label>
                  <span>{{ moenyData.mchOrderNbr }}</span>
                </li>
                <li>
                  <label>交易流水：</label>
                  <span>{{ moenyData.mchTransNbr }}</span>
                </li>
                <li>
                  <label>订单金额：</label>
                  <span v-if="moenyData.orderAmt"
                    >{{ moenyData.orderAmt | currency }} 元</span
                  >
                </li>
              </ul>
            </el-col>
            <el-col :span="24">
              <div class="see-top">付款方信息</div>
              <ul class="see-lable">
                <li>
                  <label>付款单位：</label>
                  <span>{{ moenyData.companyName }}</span>
                </li>
                <li>
                  <label>付款账号：</label>
                  <span>{{ moenyData.payerAccNbr }}</span>
                </li>
                <li>
                  <label>付款账户：</label>
                  <span>{{ moenyData.payerAccName }}</span>
                </li>
                <li>
                  <label>付款时间：</label>
                  <span v-if="moenyData.orderStrTime">{{
                    moenyData.orderStrTime | orderStrTime
                  }}</span>
                </li>
              </ul>
            </el-col>
            <el-col :span="24">
              <div class="see-top">收款方信息</div>
              <ul class="see-lable">
                <li>
                  <label>收款单位：</label>
                  <span>{{ moenyData.payeeUnitName }}</span>
                </li>
                <li>
                  <label>收款账号：</label>
                  <span>{{ moenyData.payeeAccNbr }}</span>
                </li>
                <li>
                  <label>收款账户：</label>
                  <span>{{ moenyData.payeeAccName }}</span>
                </li>
              </ul>
            </el-col>
          </el-row>
          <div class="xia">《</div>
        </div>
      </el-drawer>
      <!-- 分页 -->
      <div class="pur-pages">
        <el-pagination
          background
          :page-sizes="[50, 100, 200, 300, 500]"
          :page-size.sync="size"
          :pager-count="5"
          :current-page.sync="current"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @current-change="getpurcon"
          @size-change="getpurcon"
        >
        </el-pagination>
      </div>
      <!-- 解付弹框 -->
      <el-dialog
        v-loading="fullscreenLoading"
        :element-loading-text="'正在申请' + addData.type + '中...'"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :visible.sync="paymentShow"
        width="40%"
        :before-close="paymentShowClose"
        class="bindCardDialog"
      >
        <div slot="title" class="dialogTitle">{{ addData.type }}操作</div>
        <el-form
          style="width: 80%"
          :model="addData"
          :rules="paymentrules"
          ref="addData"
          size="small"
          label-width="150px">
          <el-form-item label="提示：">
            <span style="color: #cf1724;">最大可分润金额为 {{selRelceFee}} 元</span>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="addData.phoneNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="操作人">
            <el-input v-model="addData.username" disabled></el-input>
          </el-form-item>
          <el-form-item label="当前冻解金额">
            <el-input v-model="addData.residueFreezeAmt" disabled></el-input>
          </el-form-item>
          <el-form-item
            :label="'申请' + addData.type + '金额（元）'"
            prop="amtJf">
            <el-input v-model="addData.amtJf"></el-input>
          </el-form-item>
          <el-form-item label="分润金额（元）" prop="actTraceFee">
            <el-input v-model="addData.actTraceFee"></el-input>
          </el-form-item>
          <el-form-item label="收款企业">
            <el-input v-if="addData.type == '退款'" v-model="addData.buyerUnit" disabled></el-input>
            <el-input v-else v-model="addData.payee" disabled></el-input>
          </el-form-item>
          <el-form-item label="收款账号">
            <el-input
              v-if="addData.type == '退款'"
              v-model="addData.tNum"
              disabled
            ></el-input>
            <el-input v-else v-model="addData.accountNum" disabled></el-input>
          </el-form-item>
          <el-form-item label="收款银行">
            <el-input
              v-if="addData.type == '退款'"
              v-model="addData.ngBank"
              disabled
            ></el-input>
            <el-input
              v-else
              v-model="addData.receivingBank"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="addData.code" placeholder="请输入验证码">
              <template slot="suffix">
                <span
                  v-if="!isDisabled"
                  :class="color"
                  @click="phoneClick('addData')"
                  v-html="codephone"
                  style="margin-right: 3px; cursor: pointer"
                ></span>
                <span
                  v-if="isDisabled"
                  :class="color"
                  v-html="codephone"
                  style="cursor: pointer"
                ></span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="addData.remark1"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="addData.type == '解付'"
              type="primary"
              @click="paymentsubmitm('addData')"
              style="float: right; margin: 5% 0 0 2%"
              >解 付</el-button
            >
            <el-button
              v-if="addData.type == '退款'"
              type="primary"
              @click="refundsubmitm('addData')"
              style="float: right; margin: 5% 0 0 2%"
              >退 款</el-button
            >

            <el-button
              @click="paymentresetForm"
              style="float: right; margin: 5% 0 0 2%"
              >取 消</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 文件预览弹窗 -->
      <el-dialog
        title="文件预览"
        :visible.sync="ImgVisible"
        width="44%"
        :before-close="ImgClose"
      >
        <div
          class="imgBox"
          v-if="itemshow == true"
          v-loading="loadingimgss"
          element-loading-text="文件加载中"
        >
          <!-- <iframe :src="url" frameborder="0" width="800px" height="600px"></iframe> -->
          <div v-for="i in numPages" :key="i">
            <pdf :src="src" :page="i"></pdf>
          </div>
        </div>
        <viewer
          class="imgBox"
          :images="inmages"
          v-else
          v-loading="loadingimg"
          element-loading-text="文件加载中"
        >
          <!-- // photo 一定要一个数组，否则报错 -->
          <img
            v-for="(src, index) in inmages"
            :src="src.filePath"
            :key="index"
          />
        </viewer>
      </el-dialog>
    </div>
    <!-- 内容结束 -->
  </div>
</template>
  <script>
import slhy from "../../assets/slhy.svg";
import zst from "../../assets/zst.svg";
import pdf from "vue-pdf";
import CMapReaderFactory from "vue-pdf/src/CMapReaderFactory.js"; // 加载中文的包
export default {
  components: { pdf },
  data() {
    return {
      purSearch: "", // 搜索
      TimeArrays: [], // 时间搜索
      dataAva: false,
      loading: false,
      current: 1, // 当前页数
      size: 200, // 显示条数
      total: 0, // 总条数
      show: false,
      purTitle: "", // 标题
      drawer: false, // 查看合同抽屉
      recorddrawer: false, // 查看交易记录
      activeName: "first", // 查看tab栏
      tableData: [], // 表格数据
      seeData: {}, // 查看合同数据
      searsData: {}, // 展示图数据
      filelist: [], // 查看文件列表
      // 查看...动态
      timeData: [
        // {
        //   createtime:'2021-01-02',
        //   operation:'操作内容 ：史石平新建了合同 CG-21010801合同订单：CG-21010801'
        // }
      ],
      moenyData: {}, // 交易详情

      // 收付款企业
      companydata: [], // 付款企业列表
      skcompanydata: [], // 收款企业列表
      companyId: null, // 付款企业
      skcompanyId: null, // 收款企业

      Extended: [
        {
          dkey: "全部状态",
          dvalue: null,
        },
        {
          dkey: "未付款",
          dvalue: 1,
        },
        {
          dkey: "执行中",
          dvalue: 2,
        },
        {
          dkey: "交易关闭",
          dvalue: 3,
        }
      ], // 查询支付状态
      carriageName: null,

      // 操作确认弹窗
      paymentShow: false,
      fullscreenLoading: false, // 退款，解付加载动画
      selRelceFee:null,
      addData: {
        payee: "",
        accountNum: "",
        receivingBank: "",
        phoneNumber: "",
        username: "",
        code: "",
        residueFreezeAmt: 0,
        remake1: "", // 备注
      },
      paymentrules: {
        amtJf: [
          { required: true, message: "请输入解付金额", trigger: "change" },
          {
            pattern:
              /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "金额大小在 1 - 9999999999",
            trigger: "blur",
          },
        ],
        actTraceFee: [
          { required: true, message: "请输入分润金额（元）", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { max: 6, message: "请输入6位验证码", trigger: "blur" },
        ],
      },
      // 验证码功能
      isDisabled: false, // 控制按钮是否可以点击（false:可以点击，true:不可点击）
      color: "onPhone",
      codephone: "获取验证码",
      selcAmts: 0, // 账户余额
      zstimg: zst,
      slhyimg: slhy,
      searsData: {}, // 展示图数据
      // 文件预览
      ImgVisible: false, // 文件预览弹框
      geshi: "",
      path: "", // pdf的地址，例如：/testFile.pdf
      src: "",
      numPages: "",
      inmages: [],
      itemshow: null,
      loadingimg: true,
      loadingimgss: true,
    };
  },
  created() {},
  mounted() {
    this.purTitle = this.$route.meta.title;
    this.http = this.$store.state.http; // IP地址
    this.getpurcon();
    // 企业列表
    this.api.listByPage.selZjjgEtc({ isPtSearch: true }).then((res) => {
      if (res.data.code == 200) {
        this.companydata = res.data.data.buyEtcs; // 付款企业列表
        this.skcompanydata = res.data.data.saleEtcs; // 收款企业列表
      }
    });
  },
  methods: {
    // 操作按钮取消
    paymentShowClose() {
      this.paymentShow = false;
      this.addData.username = "";
      this.addData.payee = "";
      this.addData.accountNum = "";
      this.addData.receivingBank = "";
      this.addData.code = "";
      this.addData.residueFreezeAmt = 0;
      this.addData.remark1 = "";
      this.addData.amtJf = ""
    },
    // 取消解冻
    paymentresetForm() {
      this.paymentShow = false;
      this.addData.username = "";
      this.addData.payee = "";
      this.addData.accountNum = "";
      this.addData.receivingBank = "";
      this.addData.code = "";
      this.addData.residueFreezeAmt = 0;
      this.addData.remark1 = "";
      this.addData.amtJf = ""
    },
    // 退款确认
    refundsubmitm(formName) {
      if (this.addData.amtJf <= this.addData.residueFreezeAmt) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.fullscreenLoading = true;
            this.api.chainordr.confirmOrCancel(this.addData).then((res) => {
              if (res.data.code == 200) {
                this.fullscreenLoading = false;
                this.$message({
                  type: "success",
                  message: "申请退款金额成功!",
                });
                this.paymentShow = false;
                this.addData.username = "";
                this.addData.payee = "";
                this.addData.accountNum = "";
                this.addData.receivingBank = "";
                this.addData.code = "";
                this.addData.residueFreezeAmt = 0;
                this.addData.amtJf = "";
                this.addData.remark1 = "";
                this.getpurcon();
              }
              if (res.data.code == 602) {
                this.fullscreenLoading = false;
                this.paymentShow = false;
                this.addData.username = "";
                this.addData.payee = "";
                this.addData.accountNum = "";
                this.addData.receivingBank = "";
                this.addData.code = "";
                this.addData.residueFreezeAmt = 0;
                this.addData.amtJf = "";
                this.addData.remark1 = "";
                this.getpurcon();
              }
            });
          }
        });
      } else {
        this.addData.amtJf = "";
        this.$message({
          type: "warning",
          message: "申请退款金额不能大于冻解金额，请重新输入退款金额!",
        });
      }
    },
    // 解付确认
    paymentsubmitm(formName) {
      if (this.addData.amtJf <= this.addData.residueFreezeAmt) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.fullscreenLoading = true;
            this.api.chainordr.confirmOrCancel(this.addData).then((res) => {
              if (res.data.code == 200) {
                this.fullscreenLoading = false;
                console.log(res.data);
                this.$message({
                  type: "success",
                  message: "申请解付金额成功!",
                });
                this.paymentShow = false;
                this.addData.username = "";
                this.addData.payee = "";
                this.addData.accountNum = "";
                this.addData.receivingBank = "";
                this.addData.code = "";
                this.addData.amtJf = "";
                this.addData.residueFreezeAmt = 0;
                this.addData.remark1 = "";
                this.getpurcon();
              } else if(res.data.code == 602){
                this.fullscreenLoading = false;
              }
            }).catch((err) => {
              this.fullscreenLoading = false;
              this.$message({
                  type: "success",
                  message: "申请解付失败!",
                });
            })
          }
        });
      } else {
        this.addData.amtJf = "";
        this.$message({
          type: "warning",
          message: "申请解付金额不能大于冻解金额，请重新输入解付金额!",
        });
      }
    },
    // 解付 退款 按钮
    gopayment(data, type) {
      let date = new Date().getTime();
      this.addData.reqTimeMinners = date;
      this.addData.phoneNumber = "13994243151"; // 电话
      this.addData.username = sessionStorage.getItem("username"); // 操作人
      this.addData.residueFreezeAmt = data.residueFreezeAmt; // 剩余待解冻总额
      this.addData.contractId = data.id; // 当前合同id
      this.addData.payee = data.salesUnit; // 收款企业
      this.addData.buyerUnit = data.buyerUnit; // 付款企业
      this.addData.saleCompanyId = data.salesUnitId; // 收款企业id
      this.addData.buyCompanyId = data.buyerUnitId; // 付款企业id
      this.addData.contractCode = data.contractCode; // 合同编号

      // 解付操作
      if (type == 1) {
        this.addData.transType = 2;
        this.addData.confirmType = 1;
        this.addData.type = "解付";
        this.api.chainordr.selRelTraceFee({contractId:this.addData.contractId})
        .then(res=>{
          if(res.data.code == 200){
            this.selRelceFee = res.data.data;
          }
        }) 
        this.api.chainconfig
          .all({ searchStr: "", companyId: data.salesUnitId })
          .then((res) => {
            if (res.data.code == 200) {
              this.paymentShow = true;
              // console.log(this.paymentShow,res.data.code ,'==========');
              // 获取收款方账户信息
              this.api.chainconfig
                .all({ searchStr: "", companyId: data.salesUnitId })
                .then((res) => {
                  if (res.data.code == 200) {
                    this.addData.accountNum = res.data.data[0].acctNum; // 收款账户
                    switch (res.data.data[0].bnkId) {
                      case "01000000":
                        this.addData.receivingBank = "邮储银行";
                        break;
                      case "01020000":
                        this.addData.receivingBank = "工商银行";
                        break;
                      case "01030000":
                        this.addData.receivingBank = "农业银行";
                        break;
                      case "01040000":
                        this.addData.receivingBank = "中国银行";
                        break;
                      case "01050000":
                        this.addData.receivingBank = "建设银行";
                        break;
                      case "03010000":
                        this.addData.receivingBank = "交通银行";
                        break;
                      case "03020000":
                        this.addData.receivingBank = "中信银行";
                        break;
                      case "03030000":
                        this.addData.receivingBank = "光大银行";
                        break;
                      case "03040000":
                        this.addData.receivingBank = "华夏银行";
                        break;
                      case "03050000":
                        this.addData.receivingBank = "民生银行";
                        break;
                      case "03060000":
                        this.addData.receivingBank = "广发银行";
                        break;
                      case "03080000":
                        this.addData.receivingBank = "招商银行";
                        break;
                      case "03090000":
                        this.addData.receivingBank = "兴业银行";
                        break;
                      case "03100000":
                        this.addData.receivingBank = "浦发银行";
                        break;
                      case "04012900":
                        this.addData.receivingBank = "上海银行";
                        break;
                      case "04031000":
                        this.addData.receivingBank = "北京银行";
                        break;
                      case "04105840":
                        this.addData.receivingBank = "平安银行";
                        break;
                      case "00001014":
                        this.addData.receivingBank = "农村商业银行";
                        break;
                      case "00001015":
                        this.addData.receivingBank = "农村合作银行";
                        break;
                      case "00001016":
                        this.addData.receivingBank = "村镇银行";
                        break;
                      case "00001017":
                        this.addData.receivingBank = "民营银行";
                        break;
                      case "00001018":
                        this.addData.receivingBank = "城市信用社";
                        break;
                      case "00001019":
                        this.addData.receivingBank = "农村信用社";
                        break;
                      case "14394200":
                        this.addData.receivingBank = "江西农村信用社";
                        break;
                      case "11111111":
                        this.addData.receivingBank = "统一银行";
                        break;
                      default:
                    }
                  }
                });
            }
          });
      }
      // 退款操作
      if (type == 2) {
        this.addData.transType = 3;
        this.addData.confirmType = 3;
        this.addData.type = "退款";
        this.api.chainconfig
          .all({ searchStr: "", companyId: data.buyerUnitId })
          .then((res) => {
            if (res.data.code == 200) {
              // 获取收款方账户信息
              this.api.chainconfig
                .all({ searchStr: "", companyId: data.buyerUnitId })
                .then((res) => {
                  if (res.data.code == 200) {
                    this.addData.tNum = res.data.data[0].acctNum; // 收款账户
                    switch (res.data.data[0].bnkId) {
                      case "01000000":
                        this.addData.ngBank = "邮储银行";
                        break;
                      case "01020000":
                        this.addData.ngBank = "工商银行";
                        break;
                      case "01030000":
                        this.addData.ngBank = "农业银行";
                        break;
                      case "01040000":
                        this.addData.ngBank = "中国银行";
                        break;
                      case "01050000":
                        this.addData.ngBank = "建设银行";
                        break;
                      case "03010000":
                        this.addData.ngBank = "交通银行";
                        break;
                      case "03020000":
                        this.addData.ngBank = "中信银行";
                        break;
                      case "03030000":
                        this.addData.ngBank = "光大银行";
                        break;
                      case "03040000":
                        this.addData.ngBank = "华夏银行";
                        break;
                      case "03050000":
                        this.addData.ngBank = "民生银行";
                        break;
                      case "03060000":
                        this.addData.ngBank = "广发银行";
                        break;
                      case "03080000":
                        this.addData.ngBank = "招商银行";
                        break;
                      case "03090000":
                        this.addData.ngBank = "兴业银行";
                        break;
                      case "03100000":
                        this.addData.ngBank = "浦发银行";
                        break;
                      case "04012900":
                        this.addData.ngBank = "上海银行";
                        break;
                      case "04031000":
                        this.addData.ngBank = "北京银行";
                        break;
                      case "04105840":
                        this.addData.ngBank = "平安银行";
                        break;
                      case "00001014":
                        this.addData.ngBank = "农村商业银行";
                        break;
                      case "00001015":
                        this.addData.ngBank = "农村合作银行";
                        break;
                      case "00001016":
                        this.addData.ngBank = "村镇银行";
                        break;
                      case "00001017":
                        this.addData.ngBank = "民营银行";
                        break;
                      case "00001018":
                        this.addData.ngBank = "城市信用社";
                        break;
                      case "00001019":
                        this.addData.ngBank = "农村信用社";
                        break;
                      case "14394200":
                        this.addData.receivingBank = "江西农村信用社";
                        break;
                      case "11111111":
                        this.addData.ngBank = "统一银行";
                        break;
                      default:
                    }
                  }
                  this.paymentShow = true;
                });
            }
          });
      }
    },
    // 点击获取手机验证码付款
    phoneClick(formName) {
      this.$refs[formName].validateField(["amtJf"], (valid) => {
        if (!valid) {
          this.api.authAll.valCode({ phone: "13994243151" }).then((res) => {
            var that = this;
            if (res.data.code == 200) {
              that.isDisabled = true;
              var time = 60;
              var f = setInterval(function () {
                var newTime = time - 1;
                that.codephone = "重新获取(" + newTime + ")s";
                that.color = "onokPhone";
                time--;
                if (time == 0) {
                  that.codephone = "获取验证码";
                  that.color = "onPhone";
                  clearInterval(f);
                  that.isDisabled = false;
                }
              }, 1000);
            }
            if (res.data.code == 999) {
              that.isDisabled = false;
            }
          });
        } else {
          console.log("验证失败");
        }
      });
    },
    // phoneClick() {
    //   this.api.code.code({ account: "admin" }).then((res) => {
    //     var that = this;
    //     if (res.data.code == 200) {
    //       that.isDisabled = true;
    //       var time = 60;
    //       var f = setInterval(function () {
    //         var newTime = time - 1;
    //         that.codephone = "重新获取(" + newTime + ")s";
    //         that.color = "onokPhone";
    //         time--;
    //         if (time == 0) {
    //           that.codephone = "获取验证码";
    //           that.color = "onPhone";
    //           clearInterval(f);
    //           that.isDisabled = false;
    //         }
    //       }, 1000);
    //     }
    //     if (res.data.code == 999) {
    //       that.isDisabled = false;
    //     }
    //   });
    // },

    // 查询支付状态
    prologistics(e) {
      this.carriageName = e;
      this.current = 1;
      this.tableData = [];
      this.getpurcon();
    },
    // 付款企业
    compaypro(e) {
      this.companyId = e;
      this.current = 1;
      this.tableData = [];
      this.getpurcon();
    },
    // 收款企业
    skcompaypro(e) {
      this.skcompanyId = e;
      this.current = 1;
      this.tableData = [];
      this.getpurcon();
    },
    // 表格数据
    getpurcon() {
      this.loading = true;
      // 传参
      var listByPageData = {
        isEffect:true,
        contractStatus: this.carriageName,
        isPtSearch: true,
        buyerUnitId: this.companyId,
        salesUnitId: this.skcompanyId,
        searchStr: this.purSearch,
        startTime: this.TimeArrays != null ? this.TimeArrays[0] : "",
        endTime: this.TimeArrays != null ? this.TimeArrays[1] : "",
        page: {
          current: this.current,
          size: this.size,
        },
      };
      // 渲染表格
      this.api.listByPage.all(listByPageData).then((res) => {
        if (res.data.code == 200) {
          this.loading = false;
          if (res.data.data.records != "") {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          } else {
            this.dataAva = true;
          }
        }
      });
    },
    // 搜索功能
    search() {
      this.current = 1;
      this.tableData = [];
      this.getpurcon();
    },
    // 日期搜索
    Times() {
      this.tableData = [];
      this.getpurcon();
      this.current = 1;
    },
    // 获取企业流水
    getSelcAmts(id) {
      this.api.chainordr.selPtAmt({ companyId: id }).then((res) => {
        if (res.data.code == 200) {
          this.selcAmts = res.data.data * 1;
        }
      });
    },
    // 跳转四流合一功能    @click="zstJump"
    zstJump() {
      this.$router.push({
        name: "Relation",
        params: { paths: "Relation", id: this.pathIDS },
      });
    },
    // 查看基础信息
    allone(id) {
      this.seeData = {};
      this.filelist = [];
      this.api.cgContract.one({ id: id }).then((res) => {
        if (res.data.code == 200) {
          this.seeData = res.data.data;
        }
      });
      // 文件
      this.api.file.one({ relationId: id }).then((res) => {
        var newarr = [];
        var newobj;
        for (let i = 0; i < res.data.data.length; i++) {
          newobj = {
            name: res.data.data[i].name,
            response: {
              code: 200,
              data: [res.data.data[i]],
            },
          };
          newarr.push(newobj);
        }

        for (let i = 0; i < newarr.length; i++) {
          if (newarr[i].response.data[0].fileType == 1) {
            // 附件文件内容
            this.filelist.push(newarr[i]);
          } else if (newarr[i].response.data[0].fileType == 2) {
            // 补充协议内容
            this.enclosurefileList.push(newarr[i]);
          }
        }
        sessionStorage.setItem(
          "filelist",
          JSON.stringify(this.enclosurefileList)
        );
      });
    },
    // 查看合同
    purSee(row) {
      this.drawer = true;
      this.activeName = "first";
      // this.seeId = row.contractId;
      this.api.cgContract.one({ id: row.id }).then((res) => {
        if (res.data.code == 200) {
          this.seeData = res.data.data;
          console.log(res.data.data, "--------------");
        }
      });
      this.getSelcAmts(row.id);
      // 文件
      this.api.file.one({ relationId: row.id }).then((res) => {
        this.filelist = res.data.data;
      });
    },
    // 查看四流合一图
    sears(id, rId) {
      this.api.cgContract.sear({ contractId: id, orderId: rId }).then((res) => {
        if (res.data.code == 200) {
          this.searsData = res.data.data;
          let arr = res.data.data.contractId;
          this.pathIDS = [];
          if (typeof arr != Array) {
            this.pathIDS.push(arr);
          } else {
            for (let key in arr) {
              this.pathIDS.push(key.contract_id);
            }
          }
        }
      });
    },
    // 查看展示图
    Clicksee(row) {
      this.drawer = true;
      this.activeName = "chart";
      this.seeId = row.id;
      if (row.orderId == null) {
        this.orderId = 0;
      } else {
        this.orderId = row.orderId;
      }
      this.sears(row.id, this.orderId);
    },

    // 抽屉
    handleClick(tab) {
      // console.log(tab)
      // 判断tab栏选中状态，请求相应的接口
      if (tab.label == "基本信息") {
        this.seeData = {};
        this.filelist = [];
        this.enclosurefileList = [];
        this.allone(this.seeId);
      }
      if (tab.label == "展示图") {
        this.sears(this.seeId, this.orderId);
      }
    },
    changeActive(e) {
      e.currentTarget.className = "active";
    },
    removeActive(e) {
      e.currentTarget.className = "";
    },
    // 查看交易记录详情
    recordClick(row) {
      this.recorddrawer = true;
      // this.moenyData
      this.api.confirmPay
        .orderDetails({
          mchOrdNbr: row.mchOrderNbr,
          mchTransNbr: row.mchTransNbr,
          mchNbr: row.mchNbr,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.moenyData = res.data.data.body.orderInfoQryRtnDto;
            this.moenyData.payeeUnitName = row.payeeUnitName;
            this.moenyData.companyName = row.payUnitName;
          }
        });
    },
    // 文件预览
    scopImg(index) {
      this.ImgVisible = true;
      this.geshi = this.filelist[index].fileName.substring(
        this.filelist[index].fileName.length - 4
      );
      let arrs = [".pdf", ".PDF", "docx", "DOCX", ".doc", ".DOC"];
      if (!arrs.includes(this.geshi)) {
        this.itemshow = false;
        this.api.file
          .preFIle({ file_id: this.filelist[index].id })
          .then((res) => {
            if (res.data.code == 200) {
              this.loadingimg = false;
              this.inmages = res.data.data;
            }
          });
      } else {
        this.itemshow = true;
        this.src = pdf.createLoadingTask({
          url:
            this.http +
            "/saas-common/upload/zjjgPreviewStream?fileName=" +
            this.filelist[index].fileName +
            "&&AuthorizationId=wxda0b1c468b83bc99&&Signature=328b14094b22d98589a0b135efe853eae3cde83b53e8f7920265df1c88c6389f", // 正式环境用这个！
          CMapReaderFactory,
        });
        // 让所有页数一次性加载完，否则就只会加载第一页
        this.src.promise
          .then((pdf) => {
            console.log(pdf.numPages, "pdf");
            this.loadingimgss = false;
            this.numPages = pdf.numPages;
          })
          .catch((err) => {
            console.log(err, "err");
          });
      }
    },
    // 关闭查看图片弹窗
    ImgClose() {
      this.ImgVisible = false;
      this.src = "";
      this.inmages = [];
      this.numPages = "";
      this.path = ""; //pdf的地址，例如：/testFile.pdf
      this.geshi = "";
      this.loadingimg = true;
      this.loadingimgss = true;
    },
  },
};
</script>
  <style lang="less" scoped>
.onPhone {
  font-size: 12px;
  color: #ea222e;
}
.onokPhone {
  font-size: 12px;
  color: #bfbfbf;
}
// 订单状态样式
.statsBox {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #6fcf97;
  span {
    color: #333;
  }
}
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-select {
      width: 11%;
      margin-right: 10px;
      float: right;
    }
    .el-input {
      width: 10%;
      float: right;
      margin-right: 10px;
    }
    .el-button {
      margin-right: 10px;
      float: right;
    }
    .el-date-picker {
      width: 10%;
      float: right;
      margin-right: 10px;
    }
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  .pur-table {
    width: 100%;
    height: 94%;
  }
  // 暂无数据样式开始
  .dataAvailable {
    width: 100%;
    height: 245px;
    position: relative;
    margin-top: 185px;
    margin-bottom: 279px;
    .dataAva {
      position: absolute;
      top: 75%;
      left: 0;
      transform: translate(0, -50%);
      text-align: center;
      img {
        width: 371px;
        height: 200px;
        margin: auto;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }
      .el-button {
        margin-bottom: 100px;
      }
    }
  }
  // 暂无数据样式结束
  // 分页
  .pur-pages {
    width: 100%;
    height: 6%;
    padding: 6px 0 0 12px;
    box-sizing: border-box;
    span {
      font-size: 14px;
      line-height: 33px;
      color: #333;
    }
    .el-pagination {
      float: right;
    }
  }
}
// 内容结束
// 查看抽屉样式开始
.pur-tiao {
  width: 100%;
  height: 8px;
  background: #f5f5f5;
}
.pur-drawerNav {
  width: 100%;
  padding: 24px 0 24px 24px;
  height: calc(100% - 8px);
  .el-row {
    height: calc(100vh - 200px);
    overflow-y: scroll;
  }
  .xia {
    width: 30px;
    margin: auto;
    font-size: 16px;
    color: #828282;
    line-height: 18px;
    transform: rotate(270deg);
  }
}
.see-top {
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 45px;
  border-bottom: 1px dashed #999999;
}
.see-lable {
  padding: 15px 0;
  label {
    display: inline-block;
    width: 18%;
    font-size: 14px;
    color: #666;
  }
  li {
    min-height: 40px;
    font-size: 14px;
    line-height: 40px;
    color: #333;
    label {
      float: left;
    }
    .see-libox {
      width: 76%;
      display: inline-block;
      span {
        color: #666;
      }
      div {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
}
// 文件样式
.see-file {
  padding: 15px 0;
  span {
    font-size: 14px;
    color: #666;
  }
  dd {
    display: flow-root;
    font-size: 14px;
    line-height: 34px;
    color: #333;
    a {
      text-decoration: none;
      color: #333;
    }
    .active {
      color: #ea222e;
    }
  }
}
// 查看抽屉样式结束
// 展示图样式开始
.chart-title {
  width: 160px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  padding-left: 18px;
  color: #fff;
  background-color: #fb8138;
  border-radius: 0 15px 15px 0;
  margin: 24px 0;
}
.chart-leftImg {
  width: 50%;
  display: inline-block;
  padding-left: 20px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  img {
    width: 234px;
  }
  div {
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    text-align: center;
  }
  .htl {
    background-color: #2272ea;
    position: absolute;
    top: 20%;
    left: 42%;
    transform: translate(-50%, -50%);
    padding: 0 6px;
    border-radius: 4px;
  }
  .js {
    background-color: #f54d36;
    position: absolute;
    left: 15%;
    top: 61%;
    transform: translate(-50%, -50%);
    padding: 0 6px;
    border-radius: 4px;
  }
  .wl {
    background-color: #48caa6;
    position: absolute;
    left: 69%;
    top: 61%;
    transform: translate(-50%, -50%);
    padding: 0 6px;
    border-radius: 4px;
  }
  .zj {
    background-color: #f59d36;
    position: absolute;
    left: 12%;
    top: 106%;
    transform: translate(-50%, -50%);
    padding: 0 6px;
    border-radius: 4px;
  }
  .pjl {
    background-color: #8092c0;
    position: absolute;
    left: 66%;
    top: 106%;
    transform: translate(-50%, -50%);
    padding: 0 6px;
    border-radius: 4px;
  }
}
.chart-rightImg {
  width: 50%;
  display: inline-block;
  padding-left: 20px;
  box-sizing: border-box;
  img {
    width: 90%;
  }
}
.chart-data {
  li {
    overflow: hidden;
    width: 100%;
    padding-left: 50px;
    box-sizing: border-box;
    font-weight: 600;
    div {
      float: left;
      width: 20%;
      font-size: 14px;
      color: #333;
      line-height: 30px;
      margin: 0 40px 20px 0;
      i {
        font-size: 18px;
        line-height: 30px;
        vertical-align: middle;
        margin-right: 6px;
      }
      span {
        font-size: 16px;
        line-height: 30px;
        color: #333;
        vertical-align: middle;
      }
    }
  }
}
// 展示图样式结束

.Addbuttonbox {
  cursor: pointer;
  height: 34px;
  width: fit-content;
  background: #ffffff;
  // border: 1px solid #E4E4E4;
  // border-radius: 4px;
  font-size: 14px;
  color: #666;
  line-height: 34px;
  text-align: left;
  padding: 0 15px;
  box-sizing: border-box;
}
.Addbuttonbox:hover {
  background: rgb(250, 232, 233);
  // border: 1px solid #CF1724;
  color: #cf1724;
}
// 文件图片查看样式
.imgBox {
  width: 100%;
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
  img {
    width: 100%;
    // height:100%;
  }
}
</style>